class TechnicalHelp

    constructor: ->
        @events()
        @another()

    events: ->

    another: ->
        $('.another').on "click", (event) ->
            $('.complete').hide()
            $('.right form').show()

