class Global

    constructor: ->
        @registerEvents()

    registerEvents: ->
        # Setup global elements
        $(document).ready =>
            @documentReady()

    # Document is ready, add the class
    documentReady: ->
        @placeholders()
        # Add delay as a fix for loading lag
        setTimeout (->
            $('body').addClass 'ready'
        ), 10

    # Setup placeholders for legacy browsers
    placeholders: ->
        $('input, textarea').placeholder()
