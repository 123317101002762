Global   = new Global
Template = new Page({ type: 'template' })
Nav      = new Nav

# Pages
if Template.is 'homepage'
    new Homepage

if Template.is 'about'
    new About

if Template.is 'contact'
    new contact

if Template.is 'news'
    new news

if Template.is 'article'
    new article

if Template.is 'products'
    new products

if Template.is 'productlist'
    new productList

if Template.is 'product'
    new product

if Template.is 'technicalhelp'
    new TechnicalHelp

if Template.is 'general'
    new General

if Template.is 'category'
    new Category

